<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    name: { required: true },
  },
  data() {
    return {
      isActive: this.amIActive()
    }
  },
  computed: {
    href() {
      return '#' + this.name.toLowerCase().replace(/ /g, '-');
    }
  },
  methods: {
    amIActive() {
      if ('#' + this.name.toLowerCase().replace(/ /g, '-') === location.hash || (!location.hash && this.name === 'About')) {
        return true;
      }
      return false;
    }
  }
}
</script>

<style scoped>

</style>