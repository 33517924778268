<template>
  <div class="container">
    <NLud />
  </div>
</template>

<script>
import NLud from './components/NLud.vue'

export default {
  name: 'App',
  components: {
    NLud
  },
  methods: {
    track () {
      this.$ga.page('/')
    }
  }
}
</script>

