<template>
  <div>
    <h2 class="title is-2">Nano Commands</h2>

    <h3 class="title is-3">Select, cut, copy and paste...</h3>
    <p>
      <code>ALT + A</code> SELECT
    </p>
    <p>
      <code>CTRL + K</code> CUT
    </p>
    <p>
      <code>ALT + ^</code> COPY
    </p>
    <p>
      <code>CTRL + U</code> PASTE
    </p>


    <h3 class="title is-3">Searching...</h3>
    <p>
      <code>CTRL + W</code> SEARCH
    </p>
    <p>
      <code>CTRL + _</code> GO TO LINE
    </p>
    <p>
      <code>CTRL + \</code> REPLACE
    </p>
    <p>
      <code>CTRL + A</code> GO TO BEGINNING OF CURRENT LINE
    </p>
    <p>
      <code>ALT + \</code> GO TO FIRST LINE
    </p>
    <p>
      <code>ALT + /</code> GO TO LAST LINE
    </p>


    <h3 class="title is-3">Other...</h3>
    <p>
      <code>ALT + }</code> INDENT CURRENT LINE
    </p>
    <p>
      <code>ALT + {</code> UNINDENT CURRENT LINE
    </p>
    <p>
      <code>ALT + -</code> SCROLL UP ONE LINE KEEPING CURSOR IN SAME COLUMN
    </p>
    <p>
      <code>ALT + +</code> SCROLL DOWN ONE LINE KEEPING CURSOR IN SAME COLUMN
    </p>
    <p>
      <code>ALT + S</code> SMOOTH SCROLL ON/OFF
    </p>


    <h3 class="title is-3">FILE...</h3>
    <p>
      <code>ALT + Y</code> SYNTAX HIGHLIGHTER ON/OFF
    </p>
    <p>
      <code>CTRL + O</code> SAVE IN PLACE
    </p>
    <p>
      <code>CTRL + X</code> EXIT NANO
    </p>


    <p>See <strong>Linux Locations</strong> for the nano config file.</p>
  </div>
</template>

<script>
export default {
name: "Nano"
}
</script>

<style scoped>

</style>