<template>
  <div>
    <h2 class="title is-2">About This Website</h2>

    <p>
      I am a web developer called <strong>Neil Ludlow</strong> from Sheffield, UK, and
      <abbr title="Neil Ludlow">nlud</abbr> is a personal website that is mainly Linux reference material.
      Coming from being self-employed and working with mainly PHP, MySQL, HTML, CSS and JS, I found that in around 2016
      I needed to use more team-based production tools and began using Linux and Git a lot more.
      At that time, I started compiling notes for some of the new things I didn't want to forget, like a cheatsheet, on
      this website.
    </p>

    <h3 class="title is-3">2016</h3>

    <p>
      This website has never been particularly pretty or complex.
      It has always been a simple place to put things so I don't forget them.
      Like a cheat sheet for some things as I'm learning them.
      The colour scheme has always been black, white and grey.
    </p>

    <p>
      In 2016, this website was very basic indeed and just had some Linux and Nano information on an HTML page
      with some vertical navigation through anchor tags.
      I have re-created <a href="//nlud.uk/000/index.html">this site in 2016</a> from Wayback Machine.
    </p>

    <h3 class="title is-3">2018</h3>

    <p>
      By 2018 I had added quite a bit more information which was still displayed in a long list down an HTML page.
      At this point, I decided to hide everything and create a jQuery navigation.
      When the site loads you just see the seven headers listed vertically, by clicking each arrow you can display the
      content of that section.
      The website used <strong>jQuery</strong>, <strong>Bootstrap</strong> and <strong>Gylphicons</strong>.
      The jQuery was very simple and clear, the content was stored in include files, and I'm not too embarrassed by the
      end result in terms of coding.
      The design could always be better but I'm not a designer.
      Here is the <a href="//nlud.uk/001/index.html">2018 version of this website</a>.
    </p>

    <h3 class="title is-3">2020</h3>

    <p>
      I decided to update the website in 2020 as jQuery is very out-of-fashion.
      I'd recently built another test website with Gatsby, which is great and is a site that I can tweak occasionally
      without any pressure.
      I wanted to play with VueJS a bit more, so I decided to use this website for that using <strong>Vue.js</strong>,
      <strong>Bulma.io</strong> and <strong>Font Awesome</strong>.
    </p>

    <p>
      It's based on a VueJs tutorial that was for tab-based navigation.
      From there I added a Favicon, made the copyright year dynamic and split the content into different Vue files.
      Changed the domain to nlud.uk (from nlud.co.uk) and this website is now hosted on <strong>AWS S3</strong> with
      <strong>CloudFront</strong>, <strong>WAF</strong> and <strong>Lambda@Edge</strong>, all behind
      <strong>CloudFlare</strong>.
      It's very simple but it's better than it used to be so I'll keep it like this until I am able to work on it again.
    </p>

    <p>
      See <a href="https://shortdark.co.uk/" rel="noopener" target="_blank">shortdark</a> for more in-depth
      information I have posted over the years and links to more test sites.
      See <a href="https://nlud.uk/" rel="noopener" target="_blank">nlud.uk</a> for the latest version of this website.
    </p>

  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>

</style>