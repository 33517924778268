<template>
  <div id="root">

    <div class="content">
      <a href="https://vue.nlud.uk/">
        <h1 class="title is-1 has-text-centered">vue.nlud.uk</h1>
      </a>
      <Tabs>

        <Tab name="About">
          <About></About>
        </Tab>

        <Tab name="Bio">
          <Bio></Bio>
        </Tab>

        <Tab name="Nano Commands">
          <Nano></Nano>
        </Tab>

        <Tab name="Basic Linux Reference">
          <Linux></Linux>
        </Tab>

        <Tab name="Linux Locations">
          <LinuxLocations></LinuxLocations>
        </Tab>

        <Tab name="Bash">
          <Bash></Bash>
        </Tab>

        <Tab name="MySQL">
          <Mysql></Mysql>
        </Tab>

        <Tab name="GIT">
          <Git></Git>
        </Tab>

      </Tabs>
    </div>

    <div class="content">
      <Footer></Footer>
    </div>

  </div>

</template>



<script>
import Tabs from './Tabs.vue'
import Tab from './Tab.vue'
import Bio from './pages/Bio.vue'
import About from './pages/About.vue'
import Nano from './pages/Nano.vue'
import Linux from './pages/Linux.vue'
import LinuxLocations from './pages/LinuxLocations.vue'
import Bash from "./pages/Bash";
import Mysql from "./pages/Mysql";
import Git from "./pages/Git";
import Footer from "./Footer";

export default {
  name: 'NLud',
  components: {
    Tabs,
    Tab,
    Bio,
    About,
    Nano,
    Linux,
    LinuxLocations,
    Bash,
    Mysql,
    Git,
    Footer
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../node_modules/bulma/css/bulma.css';
@import '../../node_modules/font-awesome/css/font-awesome.css';
</style>
