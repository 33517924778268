<template>
  <div>
    <h2 class="title is-2">Bio</h2>

    <p>
      <img src="https://content.exp-sd.com/shortdark-white.jpg" style="width: 200px; height: 200px;">
    </p>

    <h3 class="title is-3">Current Stack and Tools</h3>

    <ul>
      <li>PHP</li>
      <li>MySQL</li>
      <li>Laravel</li>
      <li>AWS: S3, EB, EC2, CloudFront, WAF, Lambda@Edge, IAM, RDS</li>
      <li>Linux</li>
      <li>Apache and Nginx</li>
      <li>CloudFlare</li>
      <li>Git (and SVN)</li>
      <li>Javascript: Vanilla javascript, jQuery, GatsbyJs and VueJs</li>
      <li>CSS, Bootstrap, Tailwind</li>
    </ul>


  </div>
</template>

<script>
export default {
name: "Bio"
}
</script>

<style scoped>

</style>