<template>
  <div>
    <div class="tabs">
      <ul>
        <li v-for="(tab,idx) in tabs" :key="idx" :class="{ 'is-active' : tab.isActive }">
          <a :href="tab.href" @click="selectTab(tab)">{{ tab.name }}</a>
        </li>
      </ul>
    </div>

    <div class="tabs-details">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    whichSelected: {default: location.hash.substring(1)},
  },
  data() {
    return { tabs: [] };
  },
  created() {
    this.tabs = this.$children;
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = (tab.name === selectedTab.name);
        if (tab.name === selectedTab.name) {
          this.whichSelected = tab.name.toLowerCase().replace(/ /g, '-');
        }
      });
    }
  }
}
</script>

<style scoped>

</style>