<template>
  <div id="footer" class="has-text-centered">
    <p>
      &copy;2016-{{ new Date().getYear() -100 }} <a href="https://shortdark.co.uk/" rel="noopener noferrer" target="_blank">Shortdark</a>.
      Made using <a href="https://vuejs.org/" target="_blank" rel="noopener noferrer">Vue.js</a>,
      <a href="https://bulma.io/" target="_blank" rel="noopener noferrer">Bulma.io</a> and
      <a href="https://fontawesome.com/v4.7.0/">FontAwesome</a>.
      <a href="https://twitter.com/shortdark" target="_blank" rel="noopener noferrer" title="Shortdark Twitter">
                    <span class="icon has-text-info">
                      <i class="fa fa-twitter" aria-hidden="true"></i>
                    </span>
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>